import React from 'react'
import { graphql } from 'gatsby'
import ClientSearch from '../components/ClientSearch'
import Layout from '../components/layout'
import get from 'lodash/get'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'


const SearchTemplate = props => {
  const { pageContext } = props
  const { contentData } = pageContext
  const { allData, options } = contentData
  const heroImage = get(props, 'data.heroImage')
  return (
    <Layout>
      <div id="sub-page">      
        <Hero
          class="intro-40"
          image={heroImage.childImageSharp.gatsbyImageData}
          title="Search Eden"
          subtitle=""
          type="search"
        />
      </div>

      <section className="bg-gray-light">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <ClientSearch content={allData} engine={options} />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </Layout>
  )
}
export default SearchTemplate

export function Head({ data, location }) {
  return (
    <SEO
      title="Eden search" 
      description="Struggling to find a particular web page or piece of content? Use the Eden search to hone in on the information that you need."
      url={location.pathname} 
      image={data.heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src }
      canonical={location.pathname}                  
    />
  )
}

export const pageQuery = graphql`
  query {
    heroImage: file(name: { eq: "search" }) {
      childImageSharp {
        gatsbyImageData(quality: 90) 
      }
    }
  }
`
